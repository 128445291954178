@media (width< 800px){
    .imageText{
        font-size: 28px !important;
    }
    .imageSmText{
        font-size: 12px !important;
    }
}
@media(800px<width<1380px){
    .image-container{
        width: 90% !important;
    }
}
@media(width<800px){
    .image-container{
        width: 80% !important;
    }
    .textdetail-contain-box2{
        max-width:100% !important
    }
}
@media(width<800px){
    .textdetail-container{
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-start;
    }
    .textdetail-container-box{
        width: 90% !important;
    }
    .textdetail-contain-box2{
        margin-top: 40px !important;
        width: 100% !important;
    }
}