.btn-viewmorer {
    background: #1E1F2E !important;
    border: 1.02083px solid #373A50 !important;
    border-radius: 16.3333px !important;
    max-width: 170px !important;
    width:100% !important;
    /* height: 49px !important;  */
    font-size: 12.25px !important;
    /* font-family: 'Roboto' !important; */
    font-style: normal !important;
    font-weight: 700 !important;
    margin-top: 42px;
    margin-bottom: 75px;
}

@media only screen and (max-width: 600px) {
    .btn-viewmorer {
        font-size: 11.21px !important;
        /* width: 300px !important; */
        /* height: 44.84px !important; */

    }

    .card-box {
        /* width: 100% !important; */
        /* justify-content: space-between!important;
        align-items: center;
        align-content: center; */


    }

    .tab-box {}
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
    .box-x {
        align-items: center !important;
    }

    /* .tab-box{
        width: 100vw !important;
    } */
}

@media screen and (min-width:1200px) and (max-width:1500px) {
    .Tab-box {
        box-sizing: border-box !important;
        width: 85% !important;

    }
}