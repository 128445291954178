.tabclass{
   min-height: fit-content !important;
   padding-top: 10 !important;
   padding-bottom: 10 !important;

}
.tab-container{
    /* width: auto !important;
    overflow-x:hidden !important ; */
}
@media only screen and (max-width: 710px){
    .tabclass{
        font-size: 10.9521px !important;
        padding-left: 11.97px !important;
        padding-right: 11.97px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        min-height: 0px !important;
        min-width: 0px !important;
        border: 1.49618px solid #373A50 !important;
        border-radius: 14.6027px !important;
       
    }
}
@media only screen and (max-width: 600px){
    .tabclass{
        font-size: 10.9521px !important;
        padding-left: 11.97px !important;
        padding-right: 11.97px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        min-height: 0px !important;
        min-width: 0px !important;
        border: 1.49618px solid #373A50 !important;
        border-radius: 14.6027px !important;
       
    }
    
}

.App {
    /* font-family: sans-serif; */
    height: 100vh;
    
  }