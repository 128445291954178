* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.css-1c0guyx-MuiButtonBase-root-MuiTab-root {
  min-height: auto !important;
}

.masonryColumn {
  width: auto !important;
}

.dropdown{
  padding: 0px !important;
}

/* dropdown modal styles */
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
  border-radius: 16px !important;
}

.css-11s9wk7-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  background-color: transparent !important;
}

/* end dropdown modal styles */

@media only screen and (max-width: 1200px) {
  .masonryLayout {
    gap: 24px !important
  }

  .nav_login_btn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

@media only screen and (max-width: 900px) {
  .masonryLayout {
    gap: 24px !important
  }

  .nav_login_btn {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    height: fit-content;
  }
}

.login_btn {
  /* padding-right: 25px !important;
    padding-left: 25px !important; */
  width: 350px;
  height: 52px;
}

.profile_btn {
  width: 230px;
  height: 50px;
}

.subscribe_btn {
  /* padding-right: 25px !important;
  padding-left: 25px !important; */
  width: 350px;
  height: 52px;
}

@media (width<900px) {
  .subscribe_btn {
    /* padding-right: 25px !important;
    padding-left: 25px !important; */
    width: 260px;
    height: 52px;
  }

  .login_btn {
    /* padding-right: 25px !important;
    padding-left: 25px !important; */
    width: 220px;
    height: 42px;
  }

  .profile_btn {
    width: 260px;
    height: 50px;
  }
}

::-webkit-calendar-picker-indicator {
  border: 1px solid red;
  color: red;
  position: absolute;
  left: 82%;
  z-index: 999999;
  color: transparent;
  display: none;
  filter: invert(1) brightness(50%) sepia(100%) saturate(10000%) hue-rotate(180deg);
  background-color: rgba(255, 255, 255, 0.5);
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.MuiBox-root::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MuiBox-root {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

/* newsletter */
/* .use-theme-bg{
  background: #1F1D2B !important;
} */


.blur {
  background: rgba(25, 1, 1, 0.286);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(25, 1, 1, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}